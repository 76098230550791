import React, {useState, useEffect} from 'react';
import './About.scss';

import Swal from 'sweetalert2';
import letsConnectBg from '@backgrounds/landing-section-5-vector-desktop.svg';
import CerebroLogo from '@icons/ic-cerebro.svg';
import IcQuote from '@icons/ic-quote.svg';
import IcConnect from '@icons/ic-connect.svg';
import {contactUs} from '@repository/MailboxRepository';

function About() {
  const [inputs, setInputs] = useState({
    fullname: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const handleOnInputsChanged = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    setInputs((currentValue) => (
      {
        ...currentValue,
        [label]: value,
      }
    ));
  }

  const handleContactUs = () => {
    setIsLoading(true);
    setErrors({});
    contactUs(inputs)
      .then((res) => {
        setInputs({
          fullname: '',
          email: '',
          message: '',
        });
        Swal.fire(
          'Success',
          'You have submitted your message!',
          'success'
        );
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setErrors(err.response.data.errors);
        }
        setIsLoading(false);
        console.log(err.response);
      })
  }

  const hasValidInputs = () => {
    if (
      !inputs.fullname ||
      !inputs.email ||
      !inputs.message
    ) {
      return false;
    }
    return true;
  }

  return (
    <>
      <div id="about-introduction" className="d-flex">
        <div className="w-100 h-100 position-absolute background" />
        <div className="container d-flex flex-column align-items-center justify-content-center m-auto">
          <img src={CerebroLogo} alt="cerebro logo" />
          <div className="dosis-large-title text-pure-white text-center mb-2">
            Let’s connect with cerebro !
          </div>
          <div className="asap-subhead text-pure-white text-center description">
            Cerebro is a dream home for us, medical students around the world. we can get information and share stories, anything, anywhere, and anytime. Building relationships with other medical students without being limited by geographic barrier.
          </div>
        </div>
      </div>
      <div id="about-our-story" className="container py-5 my-3">
        <div className="row my-4 d-flex align-items-center">
          <div className="col-xl-6 col-lg-6 order-lg-1 order-2">
            <div className="dosis-title-1 text-coal-black mb-3">Our Story</div>
            <div className="description-wrapper pl-3">
              <div className="asap-body text-iron-gray">
                Cerebro is one of the online forums with the largest medical student community in the world. CEREBRO FORUM connect medical students through the online forum that consist of six categories (ideas, knowledges, collaborations, activities, issues, and chit-chat). Cerebro has established partnerships with several medical student organizations in their respective countries. Cerebro also provides a platform for students who want to share their stories and experiences with medical students around the world through the CEREBRO PODCAST. Cerebro is still on its way to improve the quality of future doctor.
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 order-lg-2 order-1 mb-xl-0 mb-3 mb-lg-0">
            <div className="w-100 h-100 image" />
          </div>
        </div>
      </div>
      <div id="about-our-vision" className="container mb-5">
        <div className="row d-flex align-items-center">
          <div className="col-lg-6 bg-azure-blue p-3 p-lg-4">
            <div className="dosis-title-1 text-pure-white mb-3">Our Vision</div>
            <div className="asap-subhead text-pure-white mb-4">
              Become the largest medical student communities worldwide.
            </div>
            <div className="d-flex align-items-start">
              <img src={IcQuote} className="mr-1" />
              <div className="mt-2 ml-2">
                <div className="asap-body text-pure-white mb-1">
                  Right now, my advice to my junior, join cerebro, all you need as medical student is there
                </div>
                <div className="asap-caption-1 font-weight-bold text-pure-white">
                  - Anonymous
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-3 p-lg-4 bg-philadelphia-blue">
            <ol className="mb-3">
              <li className="text-pure-white asap-subhead">
                Inviting all of medical students to have a voice in this discussion forum
              </li>
              <li className="text-pure-white asap-subhead">
                Cooperating with medical student organizations in their respective countries
              </li>
              <li className="text-pure-white asap-subhead">
                Helding positive health campaigns through this discussion forum
              </li>
            </ol>
            <div className="dosis-title-1 text-pure-white text-right">Our Mission</div>
          </div>
        </div>
      </div>
      {/* <div id="about-team" className="container pt-5">
        <div className="row">
          <div className="col-xl-6 mb-4 mb-xl-0">
            <div className="w-100 image" />
          </div>
          <div className="col-xl-6 d-flex flex-column align-items-end justify-content-center">
            <div className="dosis-title-1 text-right text-coal-black">Pharetra tellus mollis quam sit</div>
            <div className="dosis-title-1 text-right text-philadelphia-blue mb-3">Fringilla at porta aliquet ridiculus vel</div>
            <div className="text-right">
              Non at bibendum imperdiet placerat purus turpis sed nunc turpis augue amet morbi commodo turpis amet in ac pellentesque maecenas massa risus urna lobortis habitant augue sit vitae tincidunt elementum malesuada id quam cras tortor tempus adipiscing ut nullam hendrerit
            </div>
          </div>
        </div>
      </div> */}
      <div id="about-contact" className="container position-relative py-5">
        <div className="row mb-5">
          <div className="col-lg-5 mb-lg-0 mb-3">
            <div className="bg-philadelphia-blue p-4 position-relative connect-wrapper">
              <img src={letsConnectBg} className="position-absolute bg-lets-connect" />
              <div className="d-flex align-items-center mb-3 position-relative">
                <img src={IcConnect} className="mr-2" />
                <div className="dosis-title-1 text-pure-white">
                  Let’s Connect!
                </div>
              </div>
              <div className="d-flex align-items-center mb-2 position-relative">
                <div className="d-flex align-items-center justify-content-center bg-pure-white rounded-circle icon-contact-wrapper mr-3">
                  <div className="mdi mdi-phone mdi-18px text-azure-blue" />
                </div>
                <div className="asap-body text-pure-white">+62 822-7365-5676</div>
              </div>
              {/* <div className="d-flex align-items-center mb-2 position-relative">
                <div className="d-flex align-items-center justify-content-center bg-pure-white rounded-circle icon-contact-wrapper mr-3">
                  <div className="mdi mdi-map-marker mdi-18px text-azure-blue" />
                </div>
                <div className="asap-body text-pure-white">
                  Cerebro street address no.30<br/>
                  Full detail of the street<br />
                  Medan, North Sumatra
                </div>
              </div> */}
              <div className="d-flex align-items-center mb-5 position-relative">
                <div className="d-flex align-items-center justify-content-center bg-pure-white rounded-circle icon-contact-wrapper mr-3">
                  <div className="mdi mdi-at mdi-18px text-azure-blue" />
                </div>
                <div className="asap-body text-pure-white">
                  admin@cerebropro.com
                </div>
              </div>
              <div className="d-flex align-items-center position-relative">
                <div className="d-flex align-items-center justify-content-center rounded-circle icon-contact-wrapper mr-3">
                  <a href="https://www.facebook.com/medstudentnetwork" target="_blank">
                    <div className="mdi mdi-facebook mdi-24px text-pure-white cursor-pointer" />
                  </a>
                </div>
                <div className="d-flex align-items-center justify-content-center rounded-circle icon-contact-wrapper mr-3">
                  <a href="https://twitter.com/medstudnetwork" target="_blank">
                    <div className="mdi mdi-twitter mdi-24px text-pure-white cursor-pointer" />
                  </a>
                </div>
                <div className="d-flex align-items-center justify-content-center rounded-circle icon-contact-wrapper mr-3">
                  <a href="https://www.instagram.com/cerebro.pro/" target="_blank">
                    <div className="mdi mdi-instagram mdi-24px text-pure-white cursor-pointer" />
                  </a>
                </div>
                <div className="d-flex align-items-center justify-content-center rounded-circle icon-contact-wrapper mr-3">
                  <a href="https://www.youtube.com/channel/UCwB4c8l99GEpZH2YczH10Kg" target="_blank">
                    <div className="mdi mdi-youtube mdi-24px text-pure-white cursor-pointer" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="text-coal-black dosis-title-1 mb-3">
              Contact Us
            </div>
            <div className="row mb-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className={`form-group d-flex flex-column-reverse ${errors.fullname && 'mb-1'}`}>
                  <input
                    id="fullname"
                    name="fullname"
                    className="form-control"
                    placeholder="e.g. John Smith"
                    onChange={handleOnInputsChanged}
                    value={inputs.fullname}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                        handleContactUs();
                      }
                    }}
                    required
                  />
                  <label htmlFor='fullname' className='input-label'>
                    Full Name
                  </label>
                </div>
                {errors.fullname && (
                  <div className="asap-caption-1 text-apple-red">{errors.fullname[0]}</div>
                )}
              </div>
              <div className="col-lg-6">
                <div className={`form-group d-flex flex-column-reverse ${errors.email && 'mb-1'}`}>
                  <input
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="e.g. johnsmith@mail.com"
                    onChange={handleOnInputsChanged}
                    value={inputs.email}
                    onKeyPress={event => {
                      if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                        handleContactUs();
                      }
                    }}
                    required
                  />
                  <label htmlFor='email' className='input-label'>
                    E-mail address
                  </label>
                </div>
                {errors.email && (
                  <div className="asap-caption-1 text-apple-red">{errors.email[0]}</div>
                )}
              </div>
            </div>
            <div className={`form-group d-flex flex-column-reverse ${errors.message ? 'mb-1' : 'mb-4'}`}>
              <textarea
                id="message"
                name="message"
                className="form-control"
                placeholder="Write your message here!"
                rows="4"
                onChange={handleOnInputsChanged}
                value={inputs.message}
                required
              />
              <label htmlFor='message' className='input-label'>
                Message
              </label>
            </div>
            {errors.message && (
              <div className="asap-caption-1 text-apple-red mb-4">{errors.message[0]}</div>
            )}
            <button className="button-md button-primary" disabled={isLoading || !hasValidInputs()} onClick={handleContactUs}>
              {isLoading ? <div className="mdi mdi-loading mdi-spin" /> : "Send Message"}
            </button>
          </div>
        </div>
      </div>
      {/* <div id="about-contact" className="container px-5 mb-5">
        <div className="bg-pearl-gray content">
          <div className="dosis-large-title text-philadelphia-blue mb-3">Get in Touch</div>
          <div className="row mb-2">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <div className={`form-group d-flex flex-column-reverse ${errors.fullname && 'mb-1'}`}>
                <input
                  id="fullname"
                  name="fullname"
                  className="form-control"
                  placeholder="e.g. John Smith"
                  onChange={handleOnInputsChanged}
                  value={inputs.fullname}
                  required
                />
                <label htmlFor='fullname' className='input-label'>
                  Full Name
                </label>
              </div>
              {errors.fullname && (
                <div className="asap-caption-1 text-apple-red">{errors.fullname[0]}</div>
              )}
            </div>
            <div className="col-lg-6">
              <div className={`form-group d-flex flex-column-reverse ${errors.email && 'mb-1'}`}>
                <input
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="e.g. johnsmith@mail.com"
                  onChange={handleOnInputsChanged}
                  value={inputs.email}
                  required
                />
                <label htmlFor='email' className='input-label'>
                  E-mail address
                </label>
              </div>
              {errors.email && (
                <div className="asap-caption-1 text-apple-red">{errors.email[0]}</div>
              )}
            </div>
          </div>
          <div className={`form-group d-flex flex-column-reverse ${errors.message ? 'mb-1' : 'mb-4'}`}>
            <textarea
              id="message"
              name="message"
              className="form-control"
              placeholder="Write your message here!"
              rows="4"
              onChange={handleOnInputsChanged}
              value={inputs.message}
              required
            />
            <label htmlFor='message' className='input-label'>
              Message
            </label>
          </div>
          {errors.message && (
            <div className="asap-caption-1 text-apple-red mb-4">{errors.message[0]}</div>
          )}
          <button className="button-md button-primary" disabled={isLoading || !hasValidInputs()} onClick={handleContactUs}>
            {isLoading ? <div className="mdi mdi-loading mdi-spin" /> : "Send Message"}
          </button>
        </div>
      </div> */}
    </>
  );
}

export default About;
