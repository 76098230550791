import { httpClient as client, getToken } from "./client";

export function contactUs(data) {
  return new Promise((resolve, reject) => {
    client
      .post("contactUs", data)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}